
import { Options, Vue, setup } from "vue-class-component";
import { RegisterNewUser } from "@/services/auth.service";
import { useToast } from "vue-toastification";
@Options({
    data() {
        return {
            registerFormData: {
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
            },
        };
    },
    components: {},
    methods: {
        onSubmit() {
            RegisterNewUser(this.registerFormData)
                .then((resp: any) => {
                    this.toast.success("Account created successfully.");
                    this.$router.push("login");
                })
                .catch(({ message }: { message: string }) => {
                    this.toast.error(message);
                });
        },
    },
})
export default class Register extends Vue {
    toast = setup(() => useToast());
}
